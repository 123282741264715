<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import FilterBar from '@src/components/FilterBar'
import posCategoryModal from '@views/posCategory/posCategoryModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import PosCategoriesService from '@src/services/PosCategoriesService'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import EditTableButton from '@/src/components/EditTableButton.vue'
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        posCategoryModal,
        ConfirmationFormModal,
        FeedbackModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        EditTableButton,
        DeleteTableButton,
    },
    mixins: [pagination, swalFeedback],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.category'),
                    sortField: 'name',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filterText: '',
            additionalParameters: {},
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.category',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.category',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewPosCategory() {
            return this.getI18n(i18nKey, 'BUTTONS.new_category')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        fetch(url, params) {
            return PosCategoriesService.fetchVuetable(url, params)
        },
        i18nPosCategory(modifier) {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.category',
                modifier: modifier,
            })
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.posCategoryModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        handleDelete(id) {
            this.$refs.confirmationFormModal.showModal(false, this.deletePosCategory, id)
        },
        async deletePosCategory(deleteRecord, id) {
            if (!deleteRecord) {
                return;
            }

            if (id > 0) {
                const response = await PosCategoriesService.delete(id)
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.positiveFeedback(this.i18nPosCategory(1), 'deleted')
                    this.refreshTable()
                } else if (statusCode === '417') {
                    this.$refs.feedbackModal.showModal(statusCode, response.data.message)
                } else {
                    this.$refs.feedbackModal.showModal(statusCode, response.data.message)
                }
            }
        },
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'POSCATEGORY')"
                            :title="i18nNewPosCategory"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="point_of_sale_category"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nPosCategory(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <edit-table-button v-if="$can('UPDATE', 'POSCATEGORY')" @onClick="openModalToEdit(props.rowData)" />
                                <delete-table-button v-if="$can('DELETE', 'POSCATEGORY')" class="ml-1" @onClick="handleDelete(props.rowData.id)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <pos-category-modal
            ref="posCategoryModal"
            @refresh="reloadTable"
        />
        <import-modal
            ref="importModal"
            :csv-type="'point_of_sale_category'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TYPES.point_of_sale_category',
                    modifier: 2,
                })
            "
        />
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nPosCategory(1)"
            :operation="'deleted'"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nPosCategory(1)"
        />
    </Layout>
</template>
