import { render, staticRenderFns } from "./posCategoryModal.vue?vue&type=template&id=4ede1b86&"
import script from "./posCategoryModal.vue?vue&type=script&lang=js&"
export * from "./posCategoryModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports