import BaseService from '@src/services/BaseService'

class PosCategoriesService extends BaseService {
    constructor() {
        super('pos-categories')
    }

    removeAllPos(id, params) {
        this.axios.post(`${this.getUrl()}/${id}/remove-all-pos`, params)
    }
}

export default new PosCategoriesService()
